.main {
  width: 100%;
  height: fit-content;
  padding: 2rem 0;
}

.frame_area {
  background-color: #fff;
  width: fit-content;
  margin: auto;
  margin-top: 2rem;
  padding: 2rem;
}

.frame_area > h3 {
  width: fit-content;
  margin: auto;
  font-size: 2rem;
  color: #0c306d;
}

.frame_grid {
  margin: auto;
  margin-top: 2rem;
  display: grid;
  width: fit-content;
  grid-template-columns: repeat(4, auto);
  grid-gap: 2rem;
}

.frame {
  width: 12rem;
  height: 12rem;
  margin: auto;
  cursor: pointer;
}

.frame_1 {
  background-color: #40defa;
}
.frame_2 {
  background-color: #d3fb66;
}
.frame_3 {
  background-color: #4ef5ab;
}
.frame_4 {
  background-color: #ff84ff;
}
.frame_5 {
  background-color: #ff876b;
}
.frame_6 {
  background-color: #ffbd3b;
}
.frame_7 {
  background-color: #fcf54b;
}
.frame_8 {
  background-color: #83f954;
}

.frame:hover {
  border: 2px solid #0c306d;
}

.chosen {
  border: 2px solid #0c306d;
}

.input_area {
  margin-top: 2rem;
}

.btnArea {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputs {
  width: fit-content;
  text-align: end;
}

.input_area label {
  color: #fff;
  font-size: 1.6rem;
  font-weight: 800;
}

.input_area input {
  height: 3rem;
  width: 15rem;
  border-radius: 50px;
  margin: 1rem 2rem;
  padding: 1rem;
  font-size: 1rem;
}

.v {
  cursor: pointer;
  height: 3rem;
}

.v:hover {
  transform: scale(1.1);
}

@media (max-height: 850px) {
  .main {
    padding: 0;
  }

  .frame_area {
    margin-top: 1rem;
    padding: 1rem;
  }
}

@media (max-width: 500px) {
  .main {
    padding: 1rem 0;
  }

  .frame_area {
    margin-top: 2rem;
    padding: 2rem 0.5rem;
    text-align: center;
  }

  .frame_area > h3 {
    font-size: 1.5rem;
  }

  .frame_grid {
    grid-template-columns: repeat(2, 45%);
    grid-gap: 1rem;
    justify-content: center;
  }

  .frame {
    width: 8rem;
    height: 8rem;
  }

  .inputs {
    text-align: center;
  }

  .btnArea {
    flex-direction: column;
  }
}
