.spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #0000002d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spinner img {
  width: 10rem;
  height: 10rem;
}

.main {
  width: 100%;
  height: fit-content;
  padding: 4rem 0;
  text-align: center;
}

.crap_all {
  display: flex;
  align-items: center;
  justify-content: center;
}

.crap_all button {
  background: none;
  border: none;
  cursor: pointer;
}

.range {
  width: 20rem;
  margin: 0 3rem;
}

.crap_DIY_bg {
  width: 100%;
  height: 100vh;
  text-align: center;
  overflow-x: auto; /* Allow horizontal scrolling */
  white-space: nowrap;
}

.crap_DIY_area {
  width: fit-content;
  height: fit-content;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.draggable_div {
  width: calc(500px * 0.75);
  height: calc(500px * 0.75);
  background-color: #3f51b53f;
  position: absolute;
  display: grid;
  grid-template-rows: repeat(3, 33.3%);
  grid-template-columns: repeat(3, 33.3%);
  cursor: pointer;
  border: 2px solid #0000003f;
}

.draggable_div_mobile {
  width: calc(500px * 0.5);
  height: calc(500px * 0.5);
}

.draggable_div div {
  border: 2px solid #0000003f;
}

.upload_h3 {
  color: #fff;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.upload_btn {
  cursor: pointer;
}

input[type='file'] {
  display: none;
}

.img_upload {
  width: 20rem;
}

.error {
  margin-top: 1.2rem;
  color: red;
  font-size: 1.2rem;
  font-weight: 600;
}

.download_link {
  margin-top: 1.2rem;
}

@media (max-width: 500px) {
  .range {
    margin: 0 1rem;
  }
}
