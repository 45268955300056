.main {
  width: 100%;
  height: 100vh;
  padding: 2rem 0;
  text-align: center;
}

.title {
  color: #fff;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.displayImgArea {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4rem;
}

.displayDiv {
  background: #fff;
  padding: 6rem;
  margin: 0 5rem;
  position: relative;
}

.spin {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  width: 2rem;
  cursor: pointer;
}

.img {
  width: 30rem;
}

.spinner_container {
  background-color: #ffffffaf;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0rem;
  left: 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btns {
  background: none;
  border: none;
  cursor: pointer;
}

.btnImg {
  width: 20rem;
}

@media (max-width: 1450px) {
  .btnImg {
    width: 18rem;
  }
}

@media (max-width: 500px) {
  .displayImgArea {
    flex-direction: column-reverse;
  }

  .displayDiv {
    padding: 1rem;
    margin: 1rem 0;
  }

  .img {
    width: 90vw;
    margin: 0;
  }

  .btnImg {
    width: 90vw;
    margin: 0;
  }
}
