.english_font {
  font-family: englishRegular;
}

.hebrew_font {
  font-family: 'Assistant', sans-serif;
}

.main {
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  background-color: #febd3c;
  padding-top: 1rem;
}

.header {
  position: relative;
  height: fit-content;
  margin-bottom: -1rem;
  padding: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img_header {
  width: 70%;
}

.center {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 70%;
  margin: auto;
}

.boxes_area {
  display: grid;
  grid-template-rows: repeat(2, 50%);
  grid-template-columns: repeat(2, 50%);
  width: 55rem;
  height: fit-content;
  margin-right: 6rem;
}

.first_box {
  background-color: #f5876d;
  padding: 1rem 2rem;
  width: 100%;
  min-height: 13rem;
  font-size: 1.5rem;
  grid-column: 1/3;
  grid-row: 1/2;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.seond_box,
.third_box {
  grid-row: 2/3;
  width: 100%;
  min-height: 13rem;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.3rem;
  color: #0c306d;
}

.seond_box {
  grid-column: 1/2;
  background-color: #d3fb66;
}

.third_box {
  grid-column: 2/3;
  background-color: #4ef5ab;
}

.small_letter {
  font-size: 1.15rem;
}

.seond_box > p,
.third_box > p {
  margin: 0.5rem 0;
}

.const_box {
  background-color: #569df7;
  color: #fff;
  width: 20rem;
  height: 20rem;
  padding: 2rem;
  font-size: 1.8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.const_box :last-child {
  font-size: 1.3rem;
}

.checkbox_container {
  margin: auto;
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  width: fit-content;
}

.checkbox_container > button {
  background: none;
  border: none;
  cursor: pointer;
}

.main_img_finish {
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  background-color: #febd3c;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.imgThank {
  max-height: 80vh;
}

.sentence_download {
  margin-top: 1rem;
  cursor: pointer;
  color: #70c9a1;
  font-size: 1.2rem;
  color: #0c306d;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #febd3c;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4759f5;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6f7be4;
}

@media (max-width: 1900px) {
  .center {
    width: 90%;
  }

  .boxes_area {
    width: 45rem;
    margin-right: 8rem;
  }

  .first_box {
    width: 100%;
    min-height: 10rem;
  }

  .seond_box,
  .third_box {
    min-height: 14rem;
    padding: 1rem;
    font-size: 1rem;
  }

  .const_box {
    width: 18rem;
    height: 18rem;
    padding: 1.5rem;
    font-size: 1.6rem;
  }

  .small_letter {
    font-size: 1rem;
  }
}

@media (max-width: 500px) {
  .main {
    padding-top: 3rem;
    padding-bottom: 2rem;
  }
  .header {
    margin-bottom: 0;
    padding-top: 1.5rem;
  }

  .img_header {
    width: 100%;
  }

  .center {
    display: flex;
    flex-direction: column;
  }

  .boxes_area {
    display: block;
    width: 100%;
    margin: 0;
  }

  .first_box {
    padding: 2rem;
  }

  .const_box {
    margin: 2rem auto;
  }

  .checkbox_img {
    width: 90%;
    margin: auto;
  }

  .imgThank {
    max-height: unset;
    width: 99%;
  }
}
