.english_font {
  font-family: englishRegular;
}

.hebrew_font {
  font-family: 'Assistant', sans-serif;
}

.button {
  background-color: #fff;
  border: none;
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: 2.5rem;
  right: 2rem;
  background: none;
  font-size: 1.3rem;
  cursor: pointer;
}

@media (max-width: 500px) {
  .button {
    top: -0.3rem;
    right: 1rem;

    font-size: 1.5rem;
  }
}
