.main {
  width: 100%;
  height: fit-content;
  padding-top: 2rem;
  padding-bottom: 4rem;
}

.quote_area {
  /* background-color: #73e491; */
  background-color: #fff;
  width: fit-content;
  margin: auto;
  margin-top: 2rem;
  padding: 3rem;
}

.quote_area > h3 {
  width: fit-content;
  margin: auto;
  padding-bottom: 3rem;
  font-size: 2rem;
  color: #0c306d;
}

.quote_grid {
  display: grid;
  width: fit-content;
  margin: auto;
  grid-template-columns: repeat(3, 15rem);
  grid-row-gap: 2rem;
  grid-column-gap: 4rem;
}

.img_cuote {
  cursor: pointer;
  height: 18rem;
  width: 15rem;
  margin: auto;
}

.img_cuote:hover {
  border: 1px solid #0c306d;
}

.chosen {
  border: 1px solid #0c306d;
}

@media (max-height: 1450px) {
  .main {
    padding-top: 1rem;
  }
  .quote_area {
    padding: 1.5rem;
  }

  .quote_area > h3 {
    padding-bottom: 1.5rem;
    font-size: 1.5rem;
  }

  .quote_grid {
    grid-template-columns: repeat(3, 13rem);
  }

  .img_cuote {
    cursor: pointer;
    height: 14rem;
    width: 12rem;
  }
}

@media (max-width: 500px) {
  .main {
    padding: 0%;
  }
  .quote_area {
    padding: 2rem 0;
    text-align: center;
    width: 100%;
  }

  .quote_area > h3 {
    padding-bottom: 2rem;
    font-size: 1.5rem;
  }

  .quote_grid {
    grid-template-columns: repeat(2, 45%);
    grid-gap: 1.2rem;
    justify-content: center;
  }
  .img_cuote {
    cursor: pointer;
    height: 11rem;
    width: 9rem;
  }
}
