.first {
  width: 300px;
  padding: 8px;
  font-size: 16px;
  border-radius: 15px;
  border: none;
  text-align: center;
}

.first:focus {
  border: 2px solid #0c306d;
  outline: none;
}

.dropdownList {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 200px;
  overflow-y: auto;
  background: #febd3c;
  border-radius: 15px;
}

.dropdownList li {
  color: #fff;
  padding: 8px;
  font-size: 1.2rem;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
  text-align: center;
}

.dropdownList li:hover {
  background: #f0ddb7;
}

@media (max-width: 500px) {
  .first {
    width: 250px;
  }
}
