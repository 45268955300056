@font-face {
  font-family: 'englishRegular';
  src: url('./assets/font/english/FormaDJRDisplay-Regular.woff2')
    format('woff2');

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'hebrewRegular';
  src: url('./assets/font/hebrew/FormaDJRHebrewDisplay-Regular.woff')
    format('woff2');

  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'englishRegular';
  font-family: 'hebrewRegular';
}
