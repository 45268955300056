.english_font {
  font-family: englishRegular;
}

.hebrew_font {
  font-family: 'Assistant', sans-serif;
}

.policy {
  position: fixed;
  top: 10%;
  left: 30%;
  width: 40%;
  height: 60%;
  background: #fff;
  z-index: 11;
  background-color: #d3fb66;
  border-radius: 20px;
}

.x {
  margin: 1rem;
  margin-bottom: 0;
  cursor: pointer;
}

.x img {
  width: 1.4rem;
}

.window {
  width: 100%;
  height: 70%;
  margin: auto;
  padding: 4rem;
  padding-top: 2rem;
  overflow-y: scroll;
  font-size: 1.5rem;
}

.names {
  width: fit-content;
  margin: auto;
  width: 300px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 1rem 0;
}

.vImg {
  cursor: pointer;
  align-self: flex-start;
  margin-left: 1rem;
}

.vImg:hover {
  transform: scale(1.1);
}

@media (max-width: 500px) {
  .policy {
    left: 5%;
    top: 5%;
    width: 90%;
    height: 70%;
  }

  .window {
    padding: 2rem;
  }

  .names {
    margin-top: 1rem;
    margin: auto;
    align-items: center;
    justify-content: center;
  }

  .btnV {
    margin: 1rem auto;
    font-size: 1/8rem;
  }
}
